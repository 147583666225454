<template>
  <div class="survey-journal">
    <survey :survey="survey" />
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
import BaseJournal from "@/components/Journal/BaseJournal.vue";

export default BaseJournal.extend({
  name: "SurveyJournal",
  data() {
    return {
      survey: {}
    };
  },

  mounted() {
    this.loadSurvey();
  },

  methods: {
    loadSurvey() {
      this.styleSurvey();

      const surveyJSON = {
        surveyId: this.step.config.survey_id
      };

      this.survey = new SurveyVue.SurveyModel(surveyJSON);

      this.survey.onAfterRenderPage.add(this.addCancelButton);
      this.survey.onComplete.add(this.completeSurvey);
    },

    styleSurvey() {
      let themeColor = SurveyVue.StylesManager.ThemeColors["orange"];

      themeColor["$text-color"] = "#2c3e50";

      SurveyVue.StylesManager.applyTheme();

      SurveyVue.surveyStrings.loadingSurvey = "Your journal is loading…";
    },

    addCancelButton() {
      const btn = document.createElement("input");
      btn.type = "button";
      btn.className = "sv_cancel_btn";
      btn.value = "Cancel";
      btn.onclick = this.cancelJournal;

      const nav = document.querySelector("div.sv_nav");
      nav.appendChild(btn);
    },

    completeSurvey(result) {
      const journalButton = document.querySelector("input.sv_complete_btn");
      journalButton.value = "Journaling ...";
      journalButton.disabled = true;

      this.doJournal(JSON.stringify(result.data, null, 3));
    }
  }
});
</script>

<style>
h4.sv_page_title {
  display: none;
}

.sv_body {
  text-align: left;
}

.sv_body h3 {
  padding: 15px;
}

.sv_p_root > div:not(.sv_row),
.sv_row,
.sv_nav {
  padding-left: 10px;
  padding-right: 10px;
}

.sv_complete_btn {
  float: right;
}

h5.sv_q_title .sv_q_num,
h5.sv_q_title .sv_q_num + span {
  display: none;
}

.sv_q_title {
  font-size: 1em;
}

.sv_q_description {
  font-style: italic;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.sv_body textarea {
  border-radius: 8px;
  padding: 5px;
  width: 96%;
}
</style>
